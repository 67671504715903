<script setup lang="ts">
import TheIcon from '@/Components/TheIcon.vue'
import { useLocationSearch } from '@/Composables/locationSearch'

import {
    guestsQuantityHeader,
    inputDateRangeHeader
} from '@/Composables/TheHeaderLogic'
import { resetAllFilters } from '@/Composables/use-listing-filter'
import { fullReload, orderByDefault, partialReload, searchOptions, updateSortBy } from '@/Composables/use-listing-search'
import { showGuestsAndScrollTop, showDatesAndScrollTop, showLocationAndScrollTop } from '@/Composables/use-scroll-&-open-search'
import { useWindowSize } from '@vueuse/core'
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'

type Props = {
    location?: string
}

const props = withDefaults(defineProps<Props>(), {
    location: ''
})

const { width } = useWindowSize()
const page = usePage()

const screenSizes = computed(() => {
    return {
        isBigDesktop: width.value >= 1400,
        isDesktop: width.value < 1400 && width.value >= 1200,
        isLaptop: width.value < 1200 && width.value >= 768,
        isTab: width.value < 768 && width.value >= 576,
        isMob: width.value < 576
    }
})

const {
    url
} = useLocationSearch()

const truncatedLocation = computed(() => {
    let maxLength = 45

    if (screenSizes.value.isBigDesktop) {

        maxLength = 50

    } else if (screenSizes.value.isDesktop) {

        maxLength = 45

    } else if (screenSizes.value.isLaptop) {

        maxLength = 20

    } else if (screenSizes.value.isTab) {

        maxLength = 15

    } else if (screenSizes.value.isMob) {
        maxLength = 10
    }

    if (props.location) {
        return props.location.length > maxLength
            ? props.location.slice(0, maxLength) + '...'
            : props.location
    } else {
        return ''
    }
})

function submit (): void {
    if(!url.value) {
        showLocationAndScrollTop()

        return
    }
    
    if(isSearchOnSamePage()) {
        partialReload()
        
    } else {
        resetAllFilters()
        updateSortBy(orderByDefault)

        fullReload(url.value)
    }

    function isSearchOnSamePage (): boolean {
        return !url.value || url.value === page.props.currentUrl
    }
}

</script>

<template>
    <div class="bg-second search-box p-3">
        <div class="search-box-content bg-white">
            <div class="search-box-content-top d-flex align-items-center" @click="showLocationAndScrollTop">
                <div class="ml-2 mr-3">
                    <TheIcon
                        icon-id="search-icon-2"
                        width="24"
                        height="24"
                    />
                </div>
               
                <div class="d-flex flex-column">
                    <span class="cl-subtext search-box-category">Where do you want to go?</span>
                    <span>{{ truncatedLocation }}</span>
                </div>
            </div>
            <div class="search-box-content-bottom">
                <div class="d-flex">
                    <div class="search-box-content-bottom-date d-flex align-items-center" @click="showDatesAndScrollTop">
                        <div class="ml-2 mr-3">
                            <TheIcon icon-id="calendar" width="24" height="24" />
                        </div>
                       
                        <div class="d-flex flex-column">
                            <span class="cl-subtext search-box-category">Dates</span>
                
                            <span>{{ searchOptions.skipDate ? 'Choose date' : inputDateRangeHeader }}</span>
                        </div>
                    </div>
                    <div class="search-box-content-bottom-guests d-flex align-items-center" @click="showGuestsAndScrollTop">
                        <div class="mr-2">
                            <TheIcon icon-id="guests-icon" width="24" height="24" />
                        </div>
                       
                        <div class="d-flex flex-column">
                            <span class="cl-subtext search-box-category">Guests</span> 
                            <span> {{ guestsQuantityHeader }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <button
            class="searchMobileButton btn btn-danger btn-primary d-flex px-5 w-100 justify-content-center shadow-0 mt-3"
            @click="submit"
        >
            <span class="ml-2">Find your Hostel</span>
        </button>
    </div>
</template>

<style scoped lang="scss">
.search-box {
    border-radius: 16px;

    &-content {
        border-radius: 16px;
        padding: 14px 10px;

        &-top {
            padding-bottom: 8px;
            margin-bottom: 8px;
            border-bottom: 1px solid #787D8D;
        }

        &-bottom {
            &-date {
                flex-basis: 70%;
                padding-right: 8px;
                margin-right: 8px;
                border-right: 1px solid #787D8D;

                @media (min-width: 360px) {
                    padding-right: 16px;
                    margin-right: 16px;
                }
            }
        }
    }

    &-category {
        font-size: 12px;
        line-height: 15px;
    }
}
</style>